import * as React from "react";

import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import {
  createClaim,
  updateClaim,
  uploadAttachmentsintoS3,
} from "./../services/postServicePromise.js";
import {
  getAllClaims,
  getCognitoUser,
} from "./../services/getServicesPromise.js";

import { ICURDPICCState } from "./../state/ICURDPICCState";
import { IPICCItem } from "../modal/IPICCItem";

import { ColumnMenuCheckboxFilter } from "./columnMenu";
import PICCForm from "./PICCForm";

import "@progress/kendo-theme-default/dist/all.css";
import "./../style/DosnpPicc.css";

import Loader from "./img/loading.gif";
const initValue: any = {
  Id: 0,
  mcnNum: null,
  incidentNum: null,
  createdDate: null,
  indication: null,
  product: null,
  productCode: null,
  siteNum: null,
  siteRegion: null,
  siteCountry: null,
  siteName: null,
  piFullname: null,
  piLastname: null,
  piFirstname: null,
  piNum: null,
  patientId: null,
  patientAge: 0,
  patientInitials: null,
  patientGender: null,
  claimDescription: null,
  reportedToInsurer: false,
  death: false,
  claimNum: null,
  insuranceComments: null,
  piAssessment: null,
  mmAssessment: null,
  glpsAssessment: null,
  piAssessmentComments: null,
  mmAssessmentComments: null,
  glpsAssessmentComments: null,
  claimStatus: null,
  siteExpenses: 0,
  patientExpenses: 0,
  amountClaimed: 0,
  amountPaidByGilead: 0,
  amountPaidByInsurer: 0,
  invoiceDate: null,
  includesMedicareReport: false,
  medicareReporting: null,
  medicareReportingDate: null,
  dateOfInitialClinicalClaim: null,
  piccAssessment: null,
  assessmentDate: null,
  piModifiedBy: null,
  piModifiedDate: null,
  mmModifiedBy: null,
  mmModifiedDate: null,
  glpsModifiedBy: null,
  glpsModifiedDate: null,
  piccModifiedBy: null,
  piccModifiedDate: null,
  summaryOfAssessment: null,
  amountPaidToInsurer: 0,
};

const EditCommand = (props: any) => {
  return (
    <td>
      <button
        className="k-button k-primary"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        Edit
      </button>
    </td>
  );
};

var arrAttachmentFiles: any = [];
export default class DosnpPicc extends React.Component<any, ICURDPICCState> {
  constructor(props: any, state: any) {
    super(props);
    const datastate = {
      skip: 0,
      take: 10,
      sort: [{ field: "created_date", dir: "asc" }],
    };

    this.state = {
      status: "Ready",
      PICCItems: [],
      PICCItem: initValue,
      dataState: datastate,
      ClaimResults: [],
      openForm: false,
      formMode: "",
      ISPICCMember: true,
      filesData: [],
      FileLoader: "none",
      username: "",
    };
  }

  private async _getPICCClaims(): Promise<IPICCItem[]> {
    console.log("_getPICCClaims");
    //let permission = new SPPermission(this.props.userPermissions.value);
    //var user= await getNTName();
    //console.log("user: "+ user)
    return await getAllClaims();
  }

  private async _getCognitoUsername(): Promise<any> {
    console.log("_getCognitoUsername");
    //let permission = new SPPermission(this.props.userPermissions.value);
    //var user= await getNTName();
    //console.log("user: "+ user)

    return await getCognitoUser();
  }

  public bindClaimDetails(message: string): void {
    this._getPICCClaims().then((items) => {
      this.setState({
        PICCItems: items,
        status: message,
        ClaimResults: process(items, this.state.dataState),
      });
    });
  }

  public getCognitoUsername(): void {
    this._getCognitoUsername().then((result) => {
      this.setState({
        username: result.user,
      });
    });
  }

  public componentDidMount(): void {
    this.setState({ ISPICCMember: true });
    this.bindClaimDetails("All Records have been loaded successfully");
    this.getCognitoUsername();
  }

  public enterEdit = (item: any) => {
    let tempitem = item;
    let jsonProduct = {
      Product: tempitem.product,
      Indication: tempitem.indication,
      ProductCode: tempitem.productCode,
    };
    if (tempitem.product.Product == undefined) {
      tempitem.product = jsonProduct;
    }
    if (tempitem.site == undefined) {
      tempitem.site = {
        Site: tempitem.siteNum,
        region: tempitem.siteRegion,
        Country: tempitem.siteCountry,
      };
    }
    if (tempitem.piFullName != null) {
      if (tempitem.piFullName.PIFullName == undefined) {
        tempitem.piFullName = {
          PIFullName: tempitem.piFullName,
          PIFirstName: tempitem.piFirstName,
          PILastName: tempitem.piLastName,
          PINumber: tempitem.piNum,
        };
      }
    }
    tempitem.upload = [];
    tempitem.death = tempitem.death == 1 ? true : false;
    tempitem.reportedToInsurer = tempitem.reportedToInsurer == 1 ? true : false;
    tempitem.includesMedicareReport =
      tempitem.includesMedicareReport == 1 ? true : false;
    console.log("enterEdit : ", tempitem);
    this.setState({
      openForm: true,
      PICCItem: tempitem,
      formMode: "edit",
      filesData: [],
    });
  };

  public EditCommand = (props: any) => (
    <EditCommand {...props} enterEdit={this.enterEdit} />
  );

  public enterNewForm = () => {
    this.setState({
      openForm: true,
      PICCItem: initValue,
      formMode: "new",
      filesData: [],
    });
  };

  public handleCancelEdit = () => {
    console.log("handleCancelEdit");
    if (this.state.openForm === true) {
      this.setState({ openForm: false });
    }
  };

  public handleSubmit = async (event: any) => {
    this.setState({ FileLoader: "" });
    var claimId = 0;

    console.log(event + "handleSubmit", this.state.formMode);

    if (this.state.formMode === "new") {
      console.log("updateClaim : ", event);
      await createClaim(event, this.state.username).then((result) => {
        //get Claim id
        console.log("createClaim result : ", result);
        //get state value for Files
        claimId = result.insertId;
        var filesData = this.state.filesData;
        if (filesData == 0) {
          if (this.state.openForm === true) {
            this.setState({ openForm: false });
          }
        }
        var fileUploaded = 1;
        for (var i = 0; i < filesData.length; i++) {
          uploadAttachmentsintoS3(filesData[i], claimId).then(() => {
            if (filesData.length === fileUploaded) {
              this.bindClaimDetails(
                "All Records have been loaded successfully"
              );
              this.setState({ FileLoader: "none" });
              this.setState({ openForm: false });
            } else {
              fileUploaded = fileUploaded + 1;
            }
          });
        }
        this.bindClaimDetails("All Records have been loaded successfully");
      });
    } else if (this.state.formMode === "edit") {
      console.log("updateClaim : ", event);
      claimId = event.Id;
      await updateClaim(event, this.state.username).then((result) => {
        var filesData = this.state.filesData;
        if (filesData == 0) {
          if (this.state.openForm === true) {
            this.setState({ openForm: false });
          }
        }
        var fileUploaded = 1;
        for (var i = 0; i < filesData.length; i++) {
          uploadAttachmentsintoS3(filesData[i], claimId).then(() => {
            if (filesData.length === fileUploaded) {
              this.bindClaimDetails(
                "All Records have been loaded successfully"
              );
              this.setState({ FileLoader: "none" });
              this.setState({ openForm: false });
            } else {
              fileUploaded = fileUploaded + 1;
            }
          });
        }
        this.bindClaimDetails("All Records have been loaded successfully");
      });
    }
  };

  public attachFile = (attachedFiles: any, event: any) => {
    console.log(attachedFiles);
    var paramFiles = event.target.files;
    //attachedFiles
    if (attachedFiles !== "") {
      arrAttachmentFiles = attachedFiles; // this.state.filesData;
    }
    if (this.onValidateFile(paramFiles)) {
      for (var i = 0; i < paramFiles.length; i++) {
        var isFound = arrAttachmentFiles.filter(function (str: any) {
          return str.fileName === paramFiles[i].name;
        });
        if (isFound.length == 0) {
          console.log("File Need to be Upload :", paramFiles[i].name);
          arrAttachmentFiles.push({ Id: 0, fileName: paramFiles[i].name });
          // arrAttachmentFiles.push(paramFiles[i].name);
        } else {
          console.log("File Already Uploaded:", paramFiles[i].name);
          alert(
            paramFiles[i].name +
              " already exists. Please rename the file and try again."
          );
        }
      }
      this.setState({ filesData: event.target.files });
    } else {
      window.alert("Only .pdf,.doc,.docx,.xlsx,.xls files are allowed!");
    }
  };

  public onValidateFile = (paramFiles: any) => {
    let isAllowed = true;
    for (var i = 0; i < paramFiles.length; i++) {
      console.log("incomingFile[i].name", paramFiles[i].name);
      var fileName = paramFiles[i].name.toLowerCase();
      //".pdf,.doc,.docx,.xlsx,.xls"
      if (
        !fileName.endsWith(".pdf") &&
        !fileName.endsWith(".doc") &&
        !fileName.endsWith(".docx") &&
        !fileName.endsWith(".xlsx") &&
        !fileName.endsWith(".xls")
      ) {
        isAllowed = false;
      }
    }
    return isAllowed;
  };
  /**Export Call */
  _export: any;
  public export = () => {
    this._export.save();
  };

  public render(): React.ReactElement<any> {
    return (
      <div className={this.state.ISPICCMember ? "" : "displayNone"}>
        <div className="app_top_section">
          <div className="app_title">
            Gilead Patient Injury Claims Committee (PICC)
          </div>
          <div className="app_description">
            Gilead Patient Injury Claims Committee (PICC) description.
          </div>
        </div>
        <div className="picc_grid">
          <ExcelExport
            data={this.state.PICCItems}
            ref={(exporter) => (this._export = exporter)}
          >
            <ExcelExportColumn field="incidentNum" title="incidentNum" />
            <ExcelExportColumn field="mcnNum" title="mcnNum" />
            <ExcelExportColumn field="status" title="status" />
            <ExcelExportColumn field="createdDate" title="createdDate" />
            <ExcelExportColumn field="protocolNum" title="protocolNum" />
            <ExcelExportColumn field="indication" title="indication" />
            <ExcelExportColumn field="product" title="product" />
            <ExcelExportColumn field="productCode" title="productCode" />
            <ExcelExportColumn field="siteNum" title="siteNum" />
            <ExcelExportColumn field="siteRegion" title="siteRegion" />
            <ExcelExportColumn field="siteCountry" title="siteCountry" />
            <ExcelExportColumn field="siteName" title="siteName" />
            <ExcelExportColumn field="piFullname" title="piFullname" />
            <ExcelExportColumn field="piLastname" title="piLastname" />
            <ExcelExportColumn field="piFirstname" title="piFirstname" />
            <ExcelExportColumn field="piNum" title="piNum" />
            <ExcelExportColumn field="patientId" title="patientId" />
            <ExcelExportColumn field="patientAge" title="patientAge" />
            <ExcelExportColumn field="patientGender" title="patientGender" />
            <ExcelExportColumn
              field="claimDescription"
              title="claimDescription"
            />
            <ExcelExportColumn
              field="reportedToInsurer"
              title="reportedToInsurer"
            />
            <ExcelExportColumn field="death" title="death" />
            <ExcelExportColumn field="claimNum" title="claimNum" />
            <ExcelExportColumn
              field="insuranceComments"
              title="insuranceComments"
            />
            <ExcelExportColumn field="piAssessment" title="piAssessment" />
            <ExcelExportColumn field="mmAssessment" title="mmAssessment" />
            <ExcelExportColumn field="glpsAssessment" title="glpsAssessment" />
            <ExcelExportColumn field="claimStatus" title="claimStatus" />
            <ExcelExportColumn field="siteExpenses" title="siteExpenses" />
            <ExcelExportColumn
              field="patientExpenses"
              title="patientExpenses"
            />
            <ExcelExportColumn
              field="amountPaidByGilead"
              title="amountPaidByGilead"
            />
            <ExcelExportColumn
              field="amountPaidByInsurer"
              title="amountPaidByInsurer"
            />
            <ExcelExportColumn field="amountClaimed" title="amountClaimed" />
            <ExcelExportColumn field="invoiceNum" title="invoiceNum" />
            <ExcelExportColumn field="invoiceDate" title="invoiceDate" />
            <ExcelExportColumn field="medicareReport" title="medicareReport" />
            <ExcelExportColumn
              field="medicareReporting"
              title="medicareReporting"
            />
            <ExcelExportColumn
              field="medicareReportingDate"
              title="medicareReportingDate"
            />
            <ExcelExportColumn
              field="dateOfInitialClinicalClaim"
              title="dateOfInitialClinicalClaim"
            />
            <ExcelExportColumn field="piccAssessment" title="piccAssessment" />
            <ExcelExportColumn
              field="piccAssessmentDate"
              title="piccAssessmentDate"
            />
            <ExcelExportColumn
              field="summaryOfAssessment"
              title="summaryOfAssessment"
            />
            <ExcelExportColumn
              field="amountPaidToInsurer"
              title="amountPaidToInsurer"
            />
          </ExcelExport>
          <Grid
            style={{
              maxHeight: "700px",
              minHeight: "700px",
              border: "4px solid lightgray",
            }}
            data={this.state.ClaimResults}
            sortable
            filterable
            groupable={false}
            pageable={true}
            total={this.state.ClaimResults.length}
            {...this.state.dataState}
            onDataStateChange={(e) =>
              this.setState({
                ClaimResults: process(this.state.PICCItems, e.dataState),
                dataState: e.dataState,
              })
            }
          >
            <GridToolbar>
              <div className="grid_GridToolbar">
                <div className="addnew">
                  <button
                    className="k-button k-primary "
                    onClick={this.enterNewForm}
                    value={"0"}
                  >
                    {" "}
                    Create New Claim
                  </button>
                </div>

                <div className="grid_export">
                  <button className="k-button k-primary" onClick={this.export}>
                    {" "}
                    Export Claims
                  </button>
                </div>
              </div>
            </GridToolbar>
            <GridColumn field="incidentNum" title="Incident #" />
            <GridColumn field="mcnNum" title="MCN #" />
            <GridColumn field="protocolNum" title="Protocol" />
            <GridColumn field="siteCountry" title="Country" />
            <GridColumn field="patientId" title="Subject ID" />
            <GridColumn
              field="status"
              title="Status"
              width={120}
              filterable={false}
              columnMenu={ColumnMenuCheckboxFilter}
            />
            <GridColumn
              cell={this.EditCommand}
              filterable={false}
              width={100}
            />
          </Grid>
          {this.state.openForm && (
            <PICCForm
              item={this.state.PICCItem}
              mode={this.state.formMode}
              onSubmit={this.handleSubmit}
              fileAttach={this.attachFile}
              cancelEdit={this.handleCancelEdit}
              fileLoader={this.state.FileLoader}
            />
          )}
        </div>
      </div>
    );
  }
}
